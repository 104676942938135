import TileType from "@/game/core/model/tileType";

class SpecialityTileType extends TileType{

    constructor(id,name,value) {
        super(id,name,value);
    }

}

export default SpecialityTileType;